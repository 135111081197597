.album-pieces {
    padding-top: var(--nav-height);
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2fr);
    overflow-y: auto;
}

.album-piece-wrapper {
    grid-row: span 1;
    grid-column: span 1;
    height: 600px;
    width: 100%;
}

.album-piece {
    height: calc(100% - 8px);
    width: calc(100% - 8px);
    background-size: cover !important;
    background-position: center;
    margin: 8px;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
}

.album-title {
    position: absolute;
    top: var(--nav-height);
    /* padding-top: var(--nav-height); */
}

.about {
    padding-top: var(--nav-height);
    /* height: calc(100% - var(--nav-height)); */
    min-height: calc(100% - var(--nav-height));
    width: 100%;
}

.about-wrapper {
    height: 100%;
    min-height: calc(100%);
    padding-top: var(--nav-height);
    padding-bottom: var(--nav-height);
    flex-direction: column;
    height: 100%;
}

.about-card {
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.about-card-image-wrapper {
    aspect-ratio: 3670/5496;
    background-position: contain;
}

.about-image {
    width: 300px;
    background-position: contain;
}

.description-card {
    width: 300px;
    padding: 8px;
}

.description-card-text>h4 {
    padding: 8px;
}

@media screen and (min-width: 600px) {
    .about-wrapper {
        flex-direction: row;
        min-height: calc(100vh - var(--footer-height) - (var(--nav-height) * 3));
        width: 100%;
    }

    /* .about-card {
        height: 300px;
    }

    .about-card-image-wrapper {
        height: 300px;
    } */
}

.footer {
    z-index: 100;
    height: var(--footer-height);
    width: 100%;
    background: var(--ui-primary-background-color);
    flex-direction: column;
    /* background: black; */
    box-shadow: -1px -1px 5px 5px rgba(0,0,0,0.1);
}

.footer-email {

}

.footer-social-media-icons {
    padding: 8px;
}

#root {
  font-family: var(--font-family);
  height: 100vh;
}

h1 {
  font-size: var(--h1-font-size) !important;
  color: var(--primary-text-color);
}

nav {
  z-index: 100;
  position: absolute;
  height: var(--nav-height);
  width: 100%;
  padding-left: var(--nav-side-padding);
  padding-right: var(--nav-side-padding);
  background-color: var(--ui-primary-background-color);
  /* display: flex !important;
  justify-content: center;
  align-items: center; */
  box-shadow: 1px 5px 5px rgba(0,0,0,0.1);
}

ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  color: var(--primary-text-color);
  padding-left: var(--li-side-padding);
  padding-right: var(--li-side-padding);
}

li>a {
  padding-left: var(--li-side-padding);
  padding-right: var(--li-side-padding);
}

.page {
  flex-direction: column;
  /* background-color: var(--primary-background-color); */
  /* overflow: auto;
  height: 100%; */
}

html {
  /* width: calc(100% + var(--scrollbar-width)); */
  scrollbar-width: none;
}

.nav-title {
  flex-grow: 2;
  display: flex;
  justify-content: start;
  padding-left: 1em;
  font-weight: 900;
  text-wrap: nowrap;
}

.nav-link {
  transition: .3s;
  border-bottom: solid 2px transparent;
  padding: 1em;
}

.nav-link:hover {
  border-bottom: solid 2px black;
}

.nav-links {
  z-index: 1;
  position: absolute;
  overflow: hidden;
  background-color: var(--primary-background-color);
  top: var(--nav-height);
  height: calc(100vh - var(--nav-height));
  width: 0%;
  left: 150%;
  flex-direction: column;
  transition: 0.3s;
}

.nav-links-mobile-navigator {
  cursor: pointer;
  background-color: transparent;
  height: var(--nav-height);
  width: var(--nav-height);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links-mobile-navigator:hover>svg {
  transform: scale(1.2);
}

.nav-links-mobile-navigator>svg {
  transition: .3s;
  height: var(--icon-medium);
  width: var(--icon-medium);
}

.icon-24 {
  height: 24px;
  width: 24px;
}

.icon-28 {
  height: 28px;
  width: 28px;
}

.icon-32 {
  height: 32px;
  width: 32px;
}

@media screen and (min-width: 600px) {
  .nav-links {
    background-color: transparent;
    width: 50% !important;
    height: 100%;
    position: initial;
    display: flex;
    justify-content: center;
    align-items: end;
    flex-direction: row;
  }

  .nav-links-mobile-navigator {
    display: none;
  }

  .nav-link {
    padding: 0;
    padding-left: 1em;
    padding-right: 1em;
  }
}
